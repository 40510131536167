import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import MainNavigation from './common/MainNaivgation';

import { RecoilRoot } from 'recoil';
import RouteChangeTracker from './utils/RouteChangeTracker';
import NavigatorComponent from './common/NavigatorComponent';

const App: React.FC = () => {
  return (
    <div>
      <RecoilRoot>
        <BrowserRouter basename="/">
          <RouteChangeTracker />
          <MainNavigation NavigationComponent={NavigatorComponent} />
        </BrowserRouter>
      </RecoilRoot>
    </div>
  );
};

export default App;
