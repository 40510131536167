import React, { useEffect, useState } from 'react';
import { MenuItem, Typography } from '@material-ui/core';
import { Select } from '@mui/material';

const UserCategorySelectBox = props => {
  const { customCategory, setCustomCategory } = props;

  return (
    <>
      <Typography>그룹명</Typography>
      <Select
        value={customCategory}
        onChange={e => setCustomCategory(e.target.value)}
        displayEmpty
        // className={classes.selectEmpty}
        inputProps={{ 'aria-label': 'Without label' }}
      >
        <MenuItem value={'C'}>
          <em>유저</em>
        </MenuItem>
        <MenuItem value={'V'}>
          <em>뷰어</em>
        </MenuItem>
      </Select>
    </>
  );
};

export default UserCategorySelectBox;
