import { atom } from 'recoil';

export const customGroupList = atom({
  key: 'customGroupList',
  default: [],
});

export const atomGroupNo = atom({
  key: 'groupNo',
  default: 0,
});
