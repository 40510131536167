import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
  ListItem,
  ListItemText,
  List,
} from '@material-ui/core';
import { errorMessage, GLOBAL_HOST_URI } from '../common/CommonFunc';
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 8,
    marginTop: 22,
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

let nextSearchIndex = 1;

function HomeScreen({ location, history }) {
  const [searchCondition, setSearchCondition] = useState('D'); // 단독 D, 원부 W
  const [condition, setCondition] = useState('N'); // 상품명 D,상품코드 C
  const [keyword, setKeyword] = useState('');
  const [productCode, setProductCode] = useState('');
  const [seller, setSeller] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingDisplayIndex, setLoadingDisplayIndex] = useState(1); // 로딩 디스플레이
  const [result, setResult] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies(['custNo', 'custAuth']);
  const [cancelToken, setCancelToken] = useState();

  const PAGE_MAX_SEARCH = 25; // 회원은 25
  // const PAGE_MAX_SEARCH = 300;

  const classes = useStyles();

  const searchEvent = async initFlag => {
    if (cookies.custNo === undefined) {
      alert('로그인 후 이용해주세요.');
      return false;
    }
    if (cookies.custAuth === 'V') {
      alert('뷰어 아이디는 이용할 수 없습니다.');
      return false;
    }

    if (condition === 'C') {
      if (isNaN(productCode)) {
        alert('상품코드는 숫자만 입력가능합니다.');
        return false;
      }
    }

    if (keyword.trim() === '') {
      alert('키워드를 입력해주세요.');
      return false;
    }

    if (initFlag === true) {
      nextSearchIndex = 1;
      setLoadingDisplayIndex(1);
    } else {
      nextSearchIndex += PAGE_MAX_SEARCH;
    }

    setResult(null);
    setLoading(true);
    searchProcess();
  };

  const cancelSearch = () => {
    nextSearchIndex = 1;
    setLoadingDisplayIndex(1);
    setResult(null);
    cancelToken.cancel('검색을 취소했습니다.');
  };

  const searchProcess = async () => {
    let axiosData = undefined;

    try {
      for (
        let i = nextSearchIndex;
        i <= nextSearchIndex + PAGE_MAX_SEARCH - 1;
        i++
      ) {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        setCancelToken(source);
        if (searchCondition === 'D') {
          const { data } = await axios.post(
            GLOBAL_HOST_URI + 'search/keywordRanking',
            {
              productCode: productCode,
              keyword: keyword,
              condition: condition,
              pageIndex: i,
            },
            {
              cancelToken: source.token,
            },
          );
          axiosData = data;
        } else if (searchCondition === 'C') {
          const { data } = await axios.post(
            GLOBAL_HOST_URI + 'search/sellRanking',
            {
              seller: seller,
              keyword: keyword,
              pageIndex: i,
            },
            {
              cancelToken: source.token,
            },
          );

          axiosData = data;
        }

        if (axiosData !== undefined && axiosData.rank !== -1) {
          nextSearchIndex = 1;
          setResult(axiosData);
          setLoading(false);
          return;
        }

        setLoadingDisplayIndex(i * 2 - 1);
      }
    } catch (e) {
      alert(e);
      // errorMessage(e);
    } finally {
      setLoading(false);
    }

    if (axiosData !== undefined) setResult(axiosData);
  };
  // useEffect(() => {

  //   searchProcess();
  // }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <MyKeyword
          setProductCode={setProductCode}
          setKeyword={setKeyword}
          setCondition={setCondition}
          condition={condition}
          searchEvent={searchEvent}
          keywordLoading={loading}
        />
        <Grid item xs={12} md={8}>
          <Card>
            <CardContent>
              <Button
                href="#"
                color="primary"
                style={searchCondition === 'D' ? { fontWeight: 'bold' } : {}}
                onClick={() => {
                  setSearchCondition('D');
                  setResult(null);
                }}
              >
                단독검색
              </Button>
              <Button
                href="#"
                color="primary"
                style={searchCondition === 'W' ? { fontWeight: 'bold' } : {}}
                onClick={() => {
                  setSearchCondition('W');
                  setResult(null);
                }}
              >
                원부검색
              </Button>
              {searchCondition === 'D' ? (
                <DandokComponent
                  setCondition={setCondition}
                  setProductCode={setProductCode}
                  setResult={setResult}
                  condition={condition}
                  setKeyword={setKeyword}
                  searchEvent={searchEvent}
                  keyword={keyword}
                  productCode={productCode}
                />
              ) : (
                <WanbuComponent
                  setSeller={setSeller}
                  searchEvent={searchEvent}
                />
              )}
              <PageLoadingComponent
                loading={loading}
                loadingDisplayIndex={loadingDisplayIndex}
                searchEvent={searchEvent}
                cancelSearch={cancelSearch}
              />
              <ResultComponent
                result={result}
                searchCondition={searchCondition}
                searchEvent={searchEvent}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

const MyKeyword = props => {
  const {
    setProductCode,
    setKeyword,
    setCondition,
    condition,
    searchEvent,
    keywordLoading,
  } = props;
  const classes = useStyles();

  const [cookies, setCookie, removeCookie] = useCookies(['custNo', 'custAuth']);
  const [keywordList, setKeywordList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [keywordClick, setKeywordClick] = useState(false);

  useEffect(() => {
    const init = async () => {
      if (cookies.custNo !== undefined) {
        try {
          const { data } = await axios.post(
            GLOBAL_HOST_URI + 'cron/list',
            {
              shoppingGroupNo: 0,
            },
            {
              headers: { Authorization: cookies.custNo },
            },
          );

          if (data === -89) {
            alert('로그인시간이 만료되었습니다.\n다시 로그인해주세요.');
            removeCookie('custNo');
            removeCookie('custAuth');
            window.location.replace('/account/login');
            return;
          } else {
            setKeywordList(data);
          }
        } catch (e) {
          errorMessage(e);
        }
      }

      setLoading(false);
    };

    init();
  }, []);

  const handleListItemClick = (code, keyword, index) => {
    setKeywordClick(true);
    setProductCode(code);
    setKeyword(keyword);
    setCondition('C');
  };

  useEffect(() => {
    if (condition === 'C' && keywordClick === true) {
      setKeywordClick(false);
      searchEvent(true);
    }
  }, [keywordClick]);

  let keywordListComponent = null;

  if (cookies.custNo !== undefined) {
    if (keywordList.length > 0) {
      keywordListComponent = (
        <List>
          {keywordList.map((item, index) => {
            const productName = item.keywordCronProductName;

            return (
              <ListItem
                disabled={keywordLoading}
                key={index}
                button
                onClick={event =>
                  handleListItemClick(
                    item.keywordCronProductCode,
                    item.keywordCronKeyword,
                    2,
                  )
                }
              >
                <ListItemText
                  primary={
                    productName.length >= 50
                      ? productName.substr(0, 48) + '...'
                      : productName
                  }
                  secondary={item.keywordCronKeyword}
                />
              </ListItem>
            );
          })}
        </List>
      );
    } else {
      keywordListComponent = (
        <Typography
          component={Link}
          to="/ranking/cronkeyword"
          style={{ cursor: 'pointer' }}
        >
          상품을 추가해 보세요.
        </Typography>
      );
    }
  } else {
    if (loading === true) {
      keywordListComponent = <CircularProgress />;
    } else {
      keywordListComponent = (
        <Typography
          component={Link}
          to="/account/login"
          style={{ cursor: 'pointer' }}
        >
          로그인 후 이용해주세요.
        </Typography>
      );
    }
  }

  if (cookies.custAuth === undefined || cookies.custAuth === 'V') return null;

  return (
    <Grid item xs={12} md={4}>
      <Card>
        <CardContent>
          <Typography variant="h6" className={classes.title}>
            내 상품
          </Typography>
          <Paper style={{ maxHeight: 300, overflow: 'auto' }}>
            <div>{keywordListComponent}</div>
          </Paper>
        </CardContent>
      </Card>
    </Grid>
  );
};

const WanbuComponent = props => {
  const { setSeller, searchEvent, setProductCode } = props;

  const onKeyPress = e => {
    if (e.key == 'Enter') {
      searchEvent(true);
    }
  };

  return (
    <>
      <TextField
        label={'상품코드'}
        style={{ margin: 8 }}
        placeholder={'상품코드'}
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        onChange={e => {
          setSeller(e.target.value);
        }}
        onKeyPress={onKeyPress}
        id="sellerName"
        label="판매자"
        style={{ margin: 8 }}
        placeholder="나노코라조"
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />
    </>
  );
};
const DandokComponent = props => {
  const {
    setCondition,
    setProductCode,
    setResult,
    condition,
    setKeyword,
    searchEvent,
    keyword,
    productCode,
  } = props;

  const onKeyPress = e => {
    if (e.key == 'Enter') {
      searchEvent(true);
    }
  };

  let textLabel = {};

  if (condition === 'C') {
    textLabel = {
      label: '상품코드',
      placeholder: '26713288122',
    };
  } else if (condition === 'N') {
    textLabel = {
      label: '상품명',
      placeholder: '상품명',
    };
  } else {
    textLabel = {
      label: '스토어URL',
      placeholder: 'https://smartstore.naver.com/storeid/products/1234567890',
    };
  }

  return (
    <>
      <div>
        <br />
        <span style={{ marginRight: 8 }}>검색 조건</span>
        <ButtonGroup variant={'contained'} color="primary">
          <Button
            variant={condition === 'U' ? '' : 'outlined'}
            color="primary"
            onClick={() => {
              setCondition('U');
              setResult(null);
            }}
          >
            스토어URL
          </Button>
          <Button
            variant={condition === 'N' ? '' : 'outlined'}
            color="primary"
            onClick={() => {
              setCondition('N');
              setResult(null);
            }}
          >
            상품명
          </Button>
          <Button
            variant={condition === 'C' ? '' : 'outlined'}
            color="primary"
            onClick={() => {
              setCondition('C');
              setResult(null);
            }}
          >
            상품코드
          </Button>
        </ButtonGroup>
        <TextField
          value={productCode}
          label={textLabel.label}
          style={{ margin: 8 }}
          placeholder={textLabel.placeholder}
          fullWidth
          margin="normal"
          onChange={e => setProductCode(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
      <TextField
        onChange={e => setKeyword(e.target.value)}
        value={keyword}
        onKeyPress={onKeyPress}
        label="키워드"
        style={{ margin: 8 }}
        placeholder="휴대폰"
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />
    </>
  );
};

const PageLoadingComponent = props => {
  const { loading, loadingDisplayIndex, searchEvent, cancelSearch } = props;

  return loading === true ? (
    <div>
      <CircularProgress color="inherit" />
      <Typography>
        {loadingDisplayIndex}~{loadingDisplayIndex + 1}페이지 검색중
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        onClick={cancelSearch}
        style={{ marginBottom: 8, flex: 1 }}
      >
        검색취소
      </Button>
    </div>
  ) : (
    <Button
      variant="contained"
      color="secondary"
      onClick={() => searchEvent(true)}
      style={{ marginBottom: 8, flex: 1 }}
    >
      검색
    </Button>
  );
};

const ResultComponent = props => {
  const { result, searchCondition, searchEvent } = props;

  if (result === null) {
    return null;
  }

  if (result.rank === -1) {
    return (
      <div>
        <Alert style={{ textAlign: 'left' }}>
          순위 결과가 존재하지 않습니다. 계속 검색하시겠습니까?
        </Alert>
        <Button
          href="#"
          color="primary"
          onClick={() => {
            searchEvent();
          }}
        >
          계속 검색
        </Button>
      </div>
    );
  } else if (result.rank === -2) {
    return (
      <div>
        <Alert style={{ textAlign: 'left' }}>
          네이버에 너무 많은 트래픽을보내 잠시 중지되었습니다. 약 10초뒤에
          계속검색을 해주세요.
        </Alert>
        <Button
          href="#"
          color="primary"
          onClick={() => {
            searchEvent();
          }}
        >
          계속 검색
        </Button>
      </div>
    );
  }

  if (searchCondition === 'D') {
    const rank = result.rank;
    let page = 0;
    let pageRank = 0;

    if (rank <= 40) {
      page = 1;
    } else {
      page = parseInt((rank - 1) / 40) + 1;
    }

    if (rank % 40 === 0) {
      pageRank = 40;
    } else {
      pageRank = rank % 40;
    }

    return (
      <Alert style={{ textAlign: 'left' }}>
        <strong>{result.productName}</strong>의 순위는{' '}
        <strong>
          {rank}위 ({page}페이지, {pageRank}위)
        </strong>
      </Alert>
    );
  } else {
    return (
      <Alert style={{ textAlign: 'left' }}>
        <strong>{result.seller}</strong>의 순위는{' '}
        <strong>최저가 포함{result.realRank}위</strong>,{' '}
        <strong>최저가 제외{result.rank}위</strong>
        입니다.
      </Alert>
    );
  }

  return null;
};

export default HomeScreen;
