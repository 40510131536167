import React, { useEffect, useState } from 'react';
import { errorMessage, GLOBAL_HOST_URI } from '../../../common/CommonFunc';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import { rankingDataList } from '../../../store/RankingAtom';

const UpdateTextEdit = props => {
  const { row, setUpdateEditIndex, showUpdateKeyword } = props;
  const [cookies, setCookie, removeCookie] = useCookies(['custNo', 'custAuth']);
  const [rankingData, setRankingData] = useRecoilState(rankingDataList);

  const [keywordCronProductName, setKeywordCronProductName] = useState(
    row.keywordCronProductName,
  );
  const [keywordCronKeyword, setKeywordCronKeyword] = useState(
    row.keywordCronKeyword,
  );
  const [keywordCronProductCode, setKeywordCronProductCode] = useState(
    row.keywordCronProductCode,
  );

  const updateKeyword = async row => {
    if (keywordCronKeyword.trim() === '') {
      alert('키워드를 입력해주세요.');
      return false;
    }

    if (keywordCronProductCode.trim() === '') {
      alert('상품코드를 입력해주세요.');
      return false;
    }

    try {
      const { data } = await axios.post(
        GLOBAL_HOST_URI + 'cron/update',
        {
          keywordCronKeyword: keywordCronKeyword,
          keywordCronProductName: keywordCronProductName,
          keywordCronProductCode: keywordCronProductCode,
          keywordCronCondition: row.keywordCronCondition,
          keywordCronCategory: row.keywordCronCategory,
          keywordCronExpiredDt: row.keywordCronExpiredDt,
          keywordCronNo: row.keywordCronNo,
        },
        {
          headers: { Authorization: cookies.custNo },
        },
      );

      if (data.message === 'SHOPPING_UPDATE_SUCCESS') {
        let updatedList = rankingData.map(item => {
          if (item.keywordCronNo == row.keywordCronNo) {
            return {
              ...item,
              keywordCronKeyword: keywordCronKeyword,
              keywordCronProductName: keywordCronProductName,
              keywordCronProductCode: keywordCronProductCode,
            }; //gets everything that was already in item, and updates "done"
          }
          return item; // else return unmodified item
        });

        setRankingData(updatedList); // set state to new object with updated list
        alert('수정되었습니다.');
        showUpdateKeyword(-1);
      } else {
        alert('수정에 실패하였습니다.');
      }
    } catch (e) {
      errorMessage(e);
    }
  };

  return (
    <>
      <TableCell align="center" style={{ width: '30%' }}>
        <TextField
          id="outlined-basic"
          variant="outlined"
          size={'small'}
          margin="dense"
          value={keywordCronProductName}
          onChange={e => {
            setKeywordCronProductName(e.target.value);
          }}
          fullWidth
        />
      </TableCell>
      <TableCell align="center" style={{ width: '20%' }}>
        <TextField
          id="outlined-basic"
          variant="outlined"
          size={'small'}
          margin="dense"
          value={keywordCronKeyword}
          onChange={e => {
            setKeywordCronKeyword(e.target.value);
          }}
        />
      </TableCell>
      <TableCell align="center" style={{ width: '10%' }}>
        <TextField
          id="outlined-basic"
          variant="outlined"
          size={'small'}
          margin="dense"
          value={keywordCronProductCode}
          onChange={e => {
            setKeywordCronProductCode(e.target.value);
          }}
        />
      </TableCell>
      <TableCell align="center" style={{ width: '10%' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => updateKeyword(row)}
        >
          수정
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setUpdateEditIndex(-1)}
        >
          닫기
        </Button>
      </TableCell>
    </>
  );
};

export default UpdateTextEdit;
