import { Card, CardContent, Paper, Typography } from '@material-ui/core';
// import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
// import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(0),
    margin: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  root: {
    flexGrow: 1,
  },
}));

export default function MyPaymentScreen() {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={3} style={{ marginTop: 8 }}>
        <PremiumPaymentComponent />
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  이용중인 서비스
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                ></Typography>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  포인트 히스토리
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                ></Typography>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

const PremiumPaymentComponent = () => {
  const classes = useStyles();
  const [datePrice, setDatePrice] = useState({ key: 1, price: 8900 });

  return (
    <>
      <Grid item xs={12}>
        <CustomLevel
          title="포인트 구매"
          subtitle="키워드"
          value={datePrice}
          setValue={setDatePrice}
          contents={[
            {
              key: 1,
              title: '10000포인트',
              price: 9000,
            },
            {
              key: 2,
              title: '20000포인트',
              price: 17900,
            },
            {
              key: 3,
              title: '30000포인트',
              price: 24900,
            },
          ]}
        />
      </Grid>

      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Card>
            <CardContent>
              <div style={{ flex: 1 }}>
                <Typography gutterBottom variant="h5" component="h2">
                  전체가격 :{' '}
                  {datePrice.price
                    .toString()
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}
                </Typography>
              </div>
              <div style={{ flex: 1 }}>
                <Button variant="contained" color="primary">
                  구입
                </Button>
              </div>
            </CardContent>
          </Card>
        </Paper>
      </Grid>
    </>
  );
};

const CustomLevel = props => {
  const { title, contents, changeValue, value, setValue, subtitle } = props;
  const classes = useStyles();

  const handleChange = event => {
    contents.filter(function (object) {
      if (object.key === event.target.value) {
        setValue({ key: object.key, price: object.price });
      }
    });
  };

  return (
    <Paper className={classes.paper}>
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
          <Typography gutterBottom style={{ color: 'gray' }}>
            {subtitle}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={value.key}
              onChange={handleChange}
            >
              {contents.map(item => {
                return (
                  <MenuItem value={item.key} price={item.price}>
                    {item.title}
                  </MenuItem>
                );
              })}
            </Select>
          </Typography>
        </CardContent>
      </Card>
    </Paper>
  );
};
