import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { errorMessage, GLOBAL_HOST_URI } from '../common/CommonFunc';
import axios from 'axios';
import { DataGrid } from '@material-ui/data-grid';
import { useCookies } from 'react-cookie';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: 2,
    textAlign: 'center',
  },
}));

export default function RankingDetailChartScreen({ match }) {
  const [dataTitle, setDataTitle] = useState('');
  const [dataLabels, setDataLabels] = useState([]);
  const [dataRank, setDataRank] = useState([]);
  const [rankingData, setRankingData] = useState([]);

  const [keyword, setKeyword] = useState('');
  const [expiredDate, setExpiredDate] = useState('');
  const [regDt, setRegDt] = useState('');
  const [rank, setRank] = useState('');
  const [cookies, setCookie, removeCookie] = useCookies(['custNo']);

  useEffect(() => {
    if (cookies.custNo === undefined) {
      removeCookie('custNo');
      removeCookie('custAuth');
      alert('로그인 후 이용해주세요.');
      window.location.replace('/account/login');
      return;
    }

    const init = async () => {
      try {
        const no = match.params.num;
        const { data } = await axios.post(
          GLOBAL_HOST_URI + 'ranking/keywordDetailChartList',
          {
            keywordCronNo: parseInt(no),
          },
          {
            headers: { Authorization: cookies.custNo },
          },
        );

        const tempData = JSON.parse(JSON.stringify(data));
        setRankingData(tempData.reverse());

        setKeyword(data[0].keywordCronKeyword);
        setExpiredDate(data[0].keywordCronExpiredDt);
        setDataTitle(data[0].keywordCronProductName);
        setRegDt(data[data.length - 1].regDt);
        setRank(data[data.length - 1].keywordRankingRank + '위');

        const tempLabels = [];
        const tempDataRank = [];

        data.map(data => {
          tempLabels.push(data.regDt);
          tempDataRank.push(data.keywordRankingRank);
        });

        setDataLabels(tempLabels);
        setDataRank(tempDataRank);
      } catch (e) {
        errorMessage(e);
      }
    };

    init();
  }, []);

  return (
    <div style={{ flexGrow: 1, padding: 8, margin: 8 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} lg={3}>
          <ComponentCard title={'키워드'} contents={keyword} />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <ComponentCard title={'만료일'} contents={expiredDate} />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <ComponentCard title={'최종수집시간'} contents={regDt} />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <ComponentCard
            title={'최종랭킹'}
            contents={rank == '-1위' ? '100페이지 밖' : rank}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={6}>
          <TableGridComponent rankingData={rankingData} />
        </Grid>
        {dataRank.length > 0 ? (
          <Grid item xs={12} sm={12} lg={6}>
            <ChartGridComponent
              dataTitle={dataTitle}
              dataLabels={dataLabels}
              dataRank={dataRank}
            />
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
}

const ComponentCard = props => {
  const { contents, title } = props;
  const classes = useStyles();

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <Typography style={{ color: 'gray' }}>{title}</Typography>
        <Typography h3={true}>{contents}</Typography>
      </CardContent>
    </Card>
  );
};

const TableGridComponent = props => {
  const { rankingData } = props;

  const columns = [
    { field: 'id', headerName: 'id', hide: true },
    { field: 'keywordCronKeyword', headerName: '키워드', width: 150 },
    { field: 'regDt', headerName: '수집시간', width: 200 },
    {
      field: 'keywordRankingRank',
      headerName: '랭킹',
      width: 150,
      type: 'number',
      renderCell: params => (
        <Typography variant="subtitle1" gutterBottom>
          {params.row.keywordRankingRank === -1
            ? '100페이지 밖'
            : params.row.keywordRankingRank}
        </Typography>
      ),
    },
  ];

  return (
    <div style={{ height: 600, width: '100%', backgroundColor: 'white' }}>
      <DataGrid rows={rankingData} columns={columns} rowHeight={38} />
    </div>
  );
};

const ChartGridComponent = props => {
  const { dataTitle, dataLabels, dataRank } = props;
  const [maxRank, setMaxRank] = useState(0);
  const [minRank, setMinRank] = useState(0);
  const [chartDataRank, setChartDataRank] = useState([]);

  useEffect(() => {
    let max = 0;
    let min = 1000000;

    let newValueArray = [];

    dataRank.slice(-10).forEach(function (value) {
      if (value === -1) {
        value = 4001;
      }

      newValueArray.push(value);

      if (max <= value) {
        max = value;
      }

      if (min >= value) {
        min = value;
      }
    });

    setChartDataRank(newValueArray);
    setMaxRank(max);
    setMinRank(min);
  }, []);

  const data = {
    labels: dataLabels.slice(-10),
    datasets: [
      {
        label: dataTitle.slice(-10),
        data: chartDataRank,
        fill: false,
        tension: 0.1,
        borderColor: 'rgb(75, 192, 192)',
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          callback: function (value, index) {
            let label = dataLabels[dataLabels.length - value - 1];
            label = label.substr(0, 15);
            label = label.replace(/년\s|월\s/g, '.');
            label = label.replace('일', '');
            return label;
          },
        },
      },
      y: {
        reverse: true,
        scaleLabel: {
          display: true,
          labelString: 'Coverage',
          fontFamily: 'Montserrat',
          fontColor: 'black',
        },
        suggestedMin: minRank,
        suggestedMax: maxRank == minRank ? maxRank + 2 : maxRank,
        ticks: {
          beginAtZero: false,
          callback: function (label, index, values) {
            if (label === 4001) {
              label = '4001위↓';
            } else {
              label = parseInt(label) + '위';
            }
            return label;
          },
          stepSize: 1,
        },
      },
    },
  };

  return (
    <Card variant="outlined" style={{ height: 600, justifyContent: 'center' }}>
      {dataTitle !== undefined ? <Line data={data} options={options} /> : null}
    </Card>
  );
};
