import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import { GLOBAL_HOST_URI, errorMessage } from '../../../../common/CommonFunc';
import axios from 'axios';
import { useCookies } from 'react-cookie';

const ChangePasswordPopup = ({ open, onClose, customNo }) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [cookies] = useCookies(['custNo']);

  const handleCurrentPasswordChange = e => {
    setCurrentPassword(e.target.value);
  };

  const handleNewPasswordChange = e => {
    setNewPassword(e.target.value);
  };

  const handlePasswordChange = async () => {
    if (currentPassword !== newPassword) {
      alert('비밀번호와 비밀번호 확인이 다릅니다.');
      return false;
    }

    try {
      const { data } = await axios.put(
        `${GLOBAL_HOST_URI}admin/custom/password/${customNo}`,
        {
          customPassword: newPassword,
        },
        {
          headers: { Authorization: cookies.custNo },
        },
      );

      if (data.message === 'CUSTOM_PASSWORD_PUT_SUCCESS') {
        alert('비밀번호를 수정하였습니다.');
        onClose();
      } else {
        alert('비밀번호를 수정하는데 실패하였습니다.');
      }
    } catch (e) {
      errorMessage(e);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>비밀번호 변경</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="비밀번호"
          type="password"
          fullWidth
          value={currentPassword}
          onChange={handleCurrentPasswordChange}
        />
        <TextField
          margin="dense"
          label="비밀번호 확인"
          type="password"
          fullWidth
          value={newPassword}
          onChange={handleNewPasswordChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>닫기</Button>
        <Button onClick={handlePasswordChange}>비밀번호 변경</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordPopup;
