import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
// import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import axios from 'axios';
import { errorMessage, GLOBAL_HOST_URI } from '../../../common/CommonFunc';
import UserCategorySelectBox from './components/UserCategorySelectBox';
import UserManagementTable from './components/UserManagementTable';
import DataGridSelectBox from '../../rankingCronKeyword/components/DataGridSelectBox';
import ChangePasswordPopup from './components/ChangePasswordPopup';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    // margin: theme.spacing(0, 2, 0, 2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  root: {
    flexGrow: 1,
  },
}));

export default function RegisterAccountGrid() {
  const classes = useStyles();

  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [group, setGroup] = useState(0);
  const [customCategory, setCustomCategory] = useState('');
  const [password, setPassword] = useState('');
  const [passwordCheck, setPasswordCheck] = useState('');

  const [cookies] = useCookies(['custNo']);

  useEffect(() => {
    setCustomCategory('C');
  }, []);

  const insertViewCustom = async () => {
    if (id.trim() === '') {
      alert('아이디를 입력해주세요.');
      return false;
    }

    if (name.trim() === '') {
      alert('이름을 입력해주세요.');
      return false;
    }

    if (password !== passwordCheck) {
      alert('비밀번호를 확인해주세요.');
      return false;
    }

    if (cookies.custNo === undefined) {
      alert('로그인 후 이용해주세요.');
      window.location.replace('/account/login');
      return false;
    }

    let customGroupNo = null;

    if (group !== 0) {
      customGroupNo = group;
    }

    if (customCategory === 'V') {
      const { data } = await axios.post(
        GLOBAL_HOST_URI + 'custom/insertViewerCustom',
        {
          customId: id,
          customPassword: password,
          customName: name,
          customGroupNo: customGroupNo,
        },
        {
          headers: { Authorization: cookies.custNo },
        },
      );

      if (data === 0) {
        alert('뷰어 아이디를 추가하였습니다.');
      } else if (data === -98) {
        alert('이미 존재하는 아이디입니다.');
      } else {
        alert('추가하는데 실패하였습니다.', data);
      }
    } else {
      const { data } = await axios.post(
        GLOBAL_HOST_URI + 'custom/insertCustomCustom',
        {
          customId: id,
          customPassword: password,
          customName: name,
          customGroupNo: customGroupNo,
        },
        {
          headers: { Authorization: cookies.custNo },
        },
      );

      if (data === 0) {
        alert('아이디를 추가하였습니다.');
      } else if (data === -98) {
        alert('이미 존재하는 아이디입니다.');
      } else {
        alert('추가하는데 실패하였습니다.', data);
      }
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={3}>
        <Paper className={classes.paper}>
          <UserCategorySelectBox
            setCustomCategory={setCustomCategory}
            customCategory={customCategory}
          />
        </Paper>
      </Grid>
      <Grid item xs={3}>
        <Paper className={classes.paper}>
          <DataGridSelectBox setGroup={setGroup} group={group} />
        </Paper>
      </Grid>
      <Grid item xs={3}>
        <Paper className={classes.paper}>
          <TextField
            id="standard-full-width"
            label="아이디"
            style={{ margin: 8 }}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            value={id}
            onChange={e => {
              setId(e.target.value);
            }}
          />
        </Paper>
      </Grid>
      <Grid item xs={3}>
        <Paper className={classes.paper}>
          <TextField
            id="standard-full-width"
            label="이름"
            style={{ margin: 8 }}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            value={name}
            onChange={e => {
              setName(e.target.value);
            }}
          />
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper className={classes.paper}>
          <TextField
            id="standard-full-width"
            label="비밀번호"
            type="password"
            style={{ margin: 8 }}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            value={password}
            onChange={e => {
              setPassword(e.target.value);
            }}
          />
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper className={classes.paper}>
          <TextField
            id="standard-full-width"
            label="비밀번호 확인"
            type="password"
            style={{ margin: 8 }}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            value={passwordCheck}
            onChange={e => {
              setPasswordCheck(e.target.value);
            }}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 16 }}>
        <Paper className={classes.paper}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => insertViewCustom()}
          >
            아이디 추가
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
}
