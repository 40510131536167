import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {
  CircularProgress,
  Button,
  ButtonGroup,
  Link,
  TextField,
  Typography,
  Paper,
} from '@material-ui/core';
import axios from 'axios';
import { errorMessage, GLOBAL_HOST_URI } from '../common/CommonFunc';
import CreateIcon from '@material-ui/icons/Create';
import { useCookies } from 'react-cookie';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import { useRecoilState } from 'recoil';
import { rankingDataList } from '../store/RankingAtom';
import { Select } from '@mui/material';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function SearchKeywordModalComponent(props) {
  const classes = useStyles();
  const { init, groupNo, groupList } = props;
  const [rankingData, setRankingData] = useRecoilState(rankingDataList);
  const [open, setOpen] = React.useState(false);
  const [productCode, setProductCode] = useState('');
  const [productName, setProductName] = useState('');
  const [keyword, setKeyword] = useState('');
  const [smartStoreUrl, setSmartStoreUrl] = useState('');
  const [expiredDt, setExpiredDt] = useState('');
  const [condition, setCondition] = useState('N'); // N :상품명 C:상품코드
  const [cookies, setCookie, removeCookie] = useCookies(['custNo']);
  const [group, setGroup] = useState('0');

  useEffect(() => {
    if (open === true) {
      initialValue();
    }
  }, [open]);

  const initialValue = () => {
    setProductCode('');
    setProductName('');
    setKeyword('');
    setSmartStoreUrl('');
    setGroup(0);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addKeyword = async () => {
    if (productCode.trim() === '') {
      alert('상품코드를 입력해주세요.');
      return false;
    }

    if (productName.trim() === '') {
      alert('상품명을 입력해주세요.');
      return false;
    }

    if (keyword.trim() === '') {
      alert('키워드를 입력해주세요.');
      return false;
    }

    if (expiredDt.trim() === '') {
      alert('만료일을 입력해주세요.');
      return false;
    }

    // if(condition.trim() === "") {
    //   alert("상품명을 입력해주세요.")
    //   return false;
    // }

    try {
      const { data } = await axios.post(
        GLOBAL_HOST_URI + 'cron/insert',
        {
          keywordCronProductCode: productCode,
          keywordCronProductName: productName,
          keywordCronKeyword: keyword,
          keywordCronExpiredDt: expiredDt,
          keywordCronCondition: condition,
          keywordCronUrl: smartStoreUrl,
          shoppingGroupNo: group,
        },
        {
          headers: { Authorization: cookies.custNo },
        },
      );

      if (data.message === 'SHOPPING_INSERT_SUCCESS') {
        alert('등록에 성공하였습니다.');
        const data = await init(groupNo, cookies.custNo);
        setRankingData(data);
        handleClose();
      } else {
        alert('등록에 실패하였습니다.\n새로고침 후 다시 시도해주세요.');
      }
    } catch (e) {
      errorMessage(e);
    }
  };

  const body = (
    <Paper className={classes.paper}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h2 id="simple-modal-title">예약 키워드 추가하기</h2>
          <p id="simple-modal-description">
            예약된 키워드로 매일 10시, 14시에 순위를 체크합니다.
          </p>
        </Grid>
        <Grid item xs={12} style={{ display: 'flex' }}>
          <StoreUrlComponent
            setProductCode={setProductCode}
            setProductName={setProductName}
            setSmartStoreUrl={setSmartStoreUrl}
            smartStoreUrl={smartStoreUrl}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <TextField
            id="standard-product-code"
            label="상품코드"
            value={productCode}
            fullWidth
            margin="normal"
            onChange={e => {
              setProductCode(e.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <TextField
            id="standard-product-name"
            label="상품이름"
            value={productName}
            fullWidth
            margin="normal"
            onChange={e => {
              setProductName(e.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <TextField
            id="standard-keyword"
            label="키워드"
            onChange={e => {
              setKeyword(e.target.value);
            }}
            placeholder="ex) 컴퓨터"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <DataGridSelectBox
            group={group}
            setGroup={setGroup}
            groupList={groupList}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="datetime-local"
            label="만료일"
            type="datetime-local"
            defaultValue="2021-06-11T11:30"
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e => {
              setExpiredDt(e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12} style={{ display: 'flex' }}>
          <Button variant="outlined" onClick={handleClose} fullWidth>
            닫기
          </Button>
          <Button variant="outlined" onClick={addKeyword} fullWidth>
            추가하기
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );

  return (
    <div>
      <Button href="#" color="primary" onClick={handleOpen}>
        <CreateIcon />
        상품 등록
      </Button>
      {open === true ? body : null}
    </div>
  );
}

const StoreUrlComponent = props => {
  const { setProductCode, setProductName, smartStoreUrl, setSmartStoreUrl } =
    props;
  const [loading, setLoading] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['custNo', 'custAuth']);

  const getStoreData = async () => {
    if (smartStoreUrl.indexOf('naver.com') === -1) {
      alert('스토어url 형식으로 입력해주세요.');
      return false;
    }

    try {
      setLoading(true);

      const { data } = await axios.post(
        GLOBAL_HOST_URI + 'search/getProductAnalyze',
        {
          url: smartStoreUrl,
        },
        {
          headers: { Authorization: cookies.custNo },
        },
      );
      if (data.mainCode === '') {
        alert('상품정보가 존재하지 않습니다.\n스토어url을 다시 확인해주세요.');
      } else if (data.mainCode === '-1') {
        alert('연령확인이 필요한 상품은 해당기능을 이용할 수 없습니다.');
      } else {
        setProductCode(data.mainCode);
        setProductName(data.productTitle);
      }
    } catch (e) {
      errorMessage(e);
    }

    setLoading(false);
  };

  return (
    <>
      <TextField
        id="standard-full-width"
        label="스토어url"
        style={{ margin: 8 }}
        placeholder="https://smartstore.naver.com/storeid/products/1234567890"
        fullWidth
        required
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        value={smartStoreUrl}
        onChange={e => {
          setSmartStoreUrl(e.target.value);
        }}
      />
      {loading === true ? (
        <CircularProgress />
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={getStoreData}
          style={{ margin: 8, width: 120 }}
        >
          가져오기
        </Button>
      )}
    </>
  );
};

const DataGridSelectBox = props => {
  const { group, setGroup, groupList } = props;

  return (
    <>
      <Typography>그룹명</Typography>
      <Select
        value={group}
        onChange={e => setGroup(e.target.value)}
        displayEmpty
        // className={classes.selectEmpty}
        inputProps={{ 'aria-label': 'Without label' }}
      >
        <MenuItem value={'0'}>
          <em>없음</em>
        </MenuItem>
        {groupList.length > 0
          ? groupList.map((item, index) => {
              return (
                <MenuItem key={index} value={item.shoppingGroupNo}>
                  {item.shoppingGroupName}
                </MenuItem>
              );
            })
          : null}
      </Select>
    </>
  );
};
