import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonAppBar from './ButtonAppBar';
import DrawerNavigation from './DrawerNavigation';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    color: theme.palette.text.secondary,
    // height: '100%',
  },

  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  toolbar: theme.mixins.toolbar,
}));

export default function MainNavigation(props) {
  const [cookies, setCookie, removeCookie] = useCookies(['custNo', 'custAuth']);
  const { NavigationComponent } = props;
  const classes = useStyles();
  const location = useLocation();

  if (location.pathname === '/my/payment') {
    return (
      <div className={classes.root}>
        <main className={classes.content}>
          <NavigationComponent />
        </main>
      </div>
    );
  }

  let mainNavigationComponent = null;

  if (
    location.pathname !== '/my/accountmanagement' &&
    location.pathname !== '/my/payment-management' &&
    cookies.custAuth !== 'V'
  ) {
    mainNavigationComponent = <DrawerNavigation />;
  }

  return (
    <div className={classes.root}>
      <ButtonAppBar category={mainNavigationComponent !== null} />
      {mainNavigationComponent}
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <NavigationComponent />
      </main>
    </div>
  );
}
