import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import { Collapse } from '@material-ui/core';
import {
  Equalizer,
  EventNote,
  ExpandLess,
  ExpandMore,
  Search,
  StarBorder,
  TextFields,
} from '@material-ui/icons';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  // necessary for content to be below app bar
  nested: {
    paddingLeft: theme.spacing(4),
  },
  toolbar: theme.mixins.toolbar,
}));

const AddHome = () => {
  return (
    <ListItem button key={'홈화면 추가'} onClick={() => {}}>
      <ListItemIcon>
        <Search />
      </ListItemIcon>
      <ListItemText primary={'홈화면 추가'} />
    </ListItem>
  );
};

export default function DrawerNavigation(props) {
  const classes = useStyles();

  const [openRanking, setOpenRanking] = useState(false);

  return (
    <>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <div className={classes.toolbar} />
        <Divider />
        <List>
          <ListItem button key={'랭킹검색'} component={Link} to="/">
            <ListItemIcon>
              <Search />
            </ListItemIcon>
            <ListItemText primary={'랭킹검색'} />
          </ListItem>
          <ListItem
            button
            key={'랭킹 검색 예약'}
            onClick={() => setOpenRanking(!openRanking)}
          >
            <ListItemIcon>
              <EventNote />
            </ListItemIcon>
            <ListItemText primary={'랭킹 검색 예약'} />
            {openRanking ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openRanking} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button component={Link} to="/ranking/list">
                <ListItemIcon>
                  <Equalizer />
                </ListItemIcon>
                <ListItemText primary="랭킹보기" className={classes.nested} />
              </ListItem>
            </List>
            <List component="div" disablePadding>
              <ListItem button component={Link} to="/ranking/cronkeyword">
                <ListItemIcon>
                  <TextFields />
                </ListItemIcon>
                <ListItemText primary="내 상품" className={classes.nested} />
              </ListItem>
            </List>
          </Collapse>
          {/* <AddHome /> */}
        </List>
      </Drawer>
      <div className={classes.toolbar} />
    </>
  );
}
