import axios from 'axios';
import {
  deleteCookie,
  errorMessage,
  GLOBAL_HOST_URI,
} from '../common/CommonFunc';

const listRankingData = async (
  cookies: any,
  customGroupNo: number,
  dateFilter?: string,
) => {
  const rankingLink =
    cookies.custAuth === 'V' ? 'ranking/listViewer' : 'ranking/list';
  if (!!dateFilter) {
    let dateFilterParameter = dateFilter
      .replace(/\s.*/gi, '')
      .replace(/\-/gi, '. ');

    try {
      const { data } = await axios.post(
        GLOBAL_HOST_URI + rankingLink,
        {
          shoppingGroupNo: customGroupNo,
          regDt: dateFilterParameter,
        },
        {
          headers: { Authorization: cookies.custNo },
        },
      );

      if (data === -89) {
        alert('로그인시간이 만료되었습니다.\n다시 로그인해주세요.');
        deleteCookie('custNo');
        deleteCookie('custAuth');
        window.location.replace('/account/login');

        return [];
      } else {
        return data;
      }
    } catch (e) {
      errorMessage(e);
      alert('FDG');
      window.location.replace('/');
    }
  }

  return [];
};

const listCronData = async (
  customGroupNo: number,
  custNo: string,
  custAuth?: string | undefined,
) => {
  const cronUrl = custAuth === 'V' ? 'cron/listViewer' : 'cron/list';

  try {
    const { data } = await axios.post(
      GLOBAL_HOST_URI + 'cron/list',
      {
        shoppingGroupNo: customGroupNo,
      },
      {
        headers: { Authorization: custNo },
      },
    );

    console.log('dataaa', data);

    return data;
  } catch (e) {
    errorMessage(e);
    window.location.replace('/');
  }
};

export { listRankingData, listCronData };
