import React from 'react';
import { useCookies } from 'react-cookie';
import { Route, Switch } from 'react-router-dom';
import AccountManagement from '../screens/account/management/AccountManagement';
import MyAccountUpdateScreen from '../screens/account/privacy/MyAccountUpddateScreen';
import MyPaymentScreen from '../screens/account/privacy/MyPaymentScreen';
import MyProductsScreen from '../screens/account/privacy/MyProductsScreen';
import SignInScreen from '../screens/account/SignInScreen';
import SignUpScreen from '../screens/account/SignUpScreen';
import HomeScreen from '../screens/HomeScreen';
import ProductAnalyzeScreen from '../screens/ProductAnalyzeScreen';
import RankingCronKeywordListScreen from '../screens/rankingCronKeyword/RankingCronKeywordListScreen';
import RankingDetailChartScreen from '../screens/RankingDetailChartScreen';
import RankingDetailScreen from '../screens/RankingDetailScreen';
import RankingScreen from '../screens/RankingScreen';
import ManagementHome from '../screens/account/management/ManagementHome';
import NoticeManagement from '../screens/account/management/notice/NoticeManagement';
import Noticeregister from '../screens/account/management/notice/NoticeRegister';
import NoticeGet from '../screens/account/management/notice/NoticeGet';
import ManagementPayment from '../screens/account/management/ManagementPayment';

const NavigatorComponent: React.FC = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['custNo', 'custAuth']);

  if (cookies?.custAuth === 'V') {
    return (
      <Switch>
        <Route
          path="/ranking/detailChart/:num"
          component={RankingDetailChartScreen}
        />
        <Route path="/ranking/detail" component={RankingDetailScreen} />
        <Route path="/" component={RankingScreen} />
        <Route render={() => <div className="error">에러 페이지</div>} />
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Route path="/ranking/list" component={RankingScreen} />
        <Route path="/ranking/detail" component={RankingDetailScreen} />
        <Route
          path="/ranking/detailChart/:num"
          component={RankingDetailChartScreen}
        />
        <Route
          path="/ranking/cronkeyword"
          component={RankingCronKeywordListScreen}
        />
        <Route path="/account/login" component={SignInScreen} />
        <Route path="/account/signup" component={SignUpScreen} />
        <Route path="/productAnalyze" component={ProductAnalyzeScreen} />
        <Route path="/my/myproducts" component={MyProductsScreen} />
        <Route path="/my/myaccount" component={MyAccountUpdateScreen} />
        <Route path="/my/mypayment" component={MyPaymentScreen} />
        <Route path="/my/management" component={ManagementHome} />
        <Route path="/my/payment-management" component={ManagementPayment} />
        <Route path="/my/accountmanagement" component={AccountManagement} />
        <Route path="/my/notice/get/:noticeId" component={NoticeGet} />

        <Route
          path="/my/notice/register/:noticeId"
          component={Noticeregister}
        />
        <Route path="/my/notice/register" component={Noticeregister} />
        <Route path="/my/notice" component={NoticeManagement} />
        {/* <Route path="/my/payment/success" component={SuccessPage} />
        <Route path="/my/payment/fail" component={FailPage} />
        <Route path="/my/payment" component={CheckoutPage} /> */}
        <Route path="/" component={HomeScreen} />
        <Route render={() => <div className="error">에러 페이지</div>} />
      </Switch>
    );
  }
};

export default NavigatorComponent;
