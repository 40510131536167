import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
// import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
// import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import UserManagementTable from './components/UserManagementTable';
import WaitingAccountManagement from './WaitingAccountManagement';
import RegisterAccountGrid from './RegisterAccountGrid';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(0, 2, 0, 2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginBottom: 16,
  },
  root: {
    flexGrow: 1,
  },
}));

export default function AccountManagement() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <RegisterAccountGrid />
      </Paper>

      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <WaitingAccountManagement />
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <UserManagementTable />
        </Paper>
      </Grid>
    </div>
  );
}
