import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Button, Card, CardContent } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import SearchKeywordModalComponent from '../../components/SearchKeywordModalComponent';
import {
  errorMessage,
  expiredLoginMessage,
  GLOBAL_HOST_URI,
} from '../../common/CommonFunc';
import axios, { AxiosResponse } from 'axios';
import { useCookies } from 'react-cookie';
import AccountGroupComponent from '../../components/AccountGroupComponent';

import { initGroupList } from './utils/utils';
import { atomGroupNo, customGroupList } from '../../store/CustomGroupAtom';
import { rankingDataList } from '../../store/RankingAtom';
import FilterGroupSelectBox from './components/FilterGroupSelectBox';
import CronListComponent from './components/CronListComponent';
import { listCronData } from '../../utils/listData';

function RankingCronKeywordListScreen() {
  const [rankingData, setRankingData] = useRecoilState(rankingDataList);
  const [groupList, setGroupList] = useRecoilState(customGroupList);
  const [groupNo, setGroupNo] = useRecoilState(atomGroupNo);
  const [cookies, setCookie, removeCookie] = useCookies(['custNo', 'custAuth']);

  const init = async () => {
    try {
      setRankingData([]);
      const data = await listCronData(0, cookies.custNo);

      if (data === -89) {
        expiredLoginMessage();
        removeCookie('custNo');
        removeCookie('custAuth');
      } else {
        setRankingData(data);
      }
    } catch (e) {
      errorMessage(e);
    }
  };

  useEffect(() => {
    init();
  }, []);

  async function getGroupList() {
    const groupList = await initGroupList(cookies.custNo);
    setGroupList(groupList);
  }

  useEffect(() => {
    if (cookies.custNo === undefined) {
      alert('로그인 후 이용해주세요.');
      window.location.replace('/');
      return;
    } else {
      getGroupList();
    }
  }, []);

  async function deleteGroup() {
    if (groupNo <= 0) {
      alert('없음, 전체 그룹은 삭제할수 없습니다.');
      return false;
    }

    if (!confirm(`그룹을 삭제하시겠습니까?`)) {
      return false;
    }

    try {
      const { data }: AxiosResponse<number> = await axios.delete(
        GLOBAL_HOST_URI + 'shoppinggroup',
        {
          headers: { Authorization: cookies.custNo },
          data: { shoppingGroupNo: groupNo }, // 데이터는 'data' 속성에 포함되어야 합니다.
        },
      );

      if (data > 0) {
        alert('삭제되었습니다.');
        setGroupNo(0);
        await getGroupList();

        const listData = await listCronData(0, cookies.custNo);
        console.log('listData', listData);
        setRankingData(listData);
      } else {
        alert('삭제에 실패하였습니다.' + data);
      }
    } catch (e) {}
  }

  return (
    <div style={{ flexGrow: 1, padding: 8, margin: 8 }}>
      <Grid container spacing={3}>
        {cookies.custAuth !== 'V' ? (
          <Grid item xs={12}>
            <AccountGroupComponent />
            <SearchKeywordModalComponent
              init={listCronData}
              groupList={groupList}
            />
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardContent>
              <FilterGroupSelectBox />
              <Button
                variant="contained"
                color={'default'}
                style={{ marginLeft: 16 }}
                onClick={() => deleteGroup()}
              >
                그룹삭제
              </Button>
            </CardContent>
          </Card>
        </Grid>
        <CronListComponent />
      </Grid>
    </div>
  );
}

export default RankingCronKeywordListScreen;
