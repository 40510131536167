const GLOBAL_HOST_URI = 'https://heroranking.kro.kr:18089/';
// const GLOBAL_HOST_URI = 'http:///localhost:18089/';

import { Backdrop, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const errorMessage = e => {
  alert('에러가 발생하였습니다.\n잠시후 다시 시도해주세요.', e);
  console.warn('error:', e);
};

const deleteCookie = cookieName => {
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

const expiredLoginMessage = e => {
  alert('로그인시간이 만료되었습니다.\n다시 로그인해주세요.');
  deleteCookie('custNo');
  deleteCookie('custAuth');
  window.location.replace('/account/login');
  console.warn('error:', e);
  return;
};

const ProgressLoadingComponent = props => {
  const { loading } = props;
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={loading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export {
  GLOBAL_HOST_URI,
  errorMessage,
  ProgressLoadingComponent,
  expiredLoginMessage,
  deleteCookie,
};
