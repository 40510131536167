import React, { useState } from 'react';
// import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
// import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Chip, Divider, Paper } from '@material-ui/core';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href={'/'}>
        Hero Ranking
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  root: {
    flexGrow: 1,
  },
}));

export default function MyProductsScreen({ location, history }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3} style={{ marginTop: 8 }}>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <TextField
              id="standard-full-width"
              label="상품코드"
              style={{ margin: 8 }}
              placeholder="1234567890"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <TextField
              id="standard-full-width"
              label="상품명"
              style={{ margin: 8 }}
              placeholder="핸드폰 거치대"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <TextField
              id="standard-full-width"
              label="상품url"
              style={{ margin: 8 }}
              placeholder="핸드폰 거치대"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Paper>
        </Grid>
        <KeywordComponent />
      </Grid>
    </div>
  );
}

const KeywordComponent = () => {
  const classes = useStyles();
  const [keywordArray, setKeywordArray] = useState([]);
  const [keyword, setKeyword] = useState('');

  const addKeyword = () => {
    for (let i = 0; i < keywordArray.length; i++) {
      if (keywordArray[i].trim() === keyword.trim()) {
        alert('이미 존재하는 키워드입니다.');
        return;
      }
    }

    setKeywordArray((oldArray) => [...oldArray, keyword]);
  };

  const removeKeyword = (keywordName) => {
    setKeywordArray(keywordArray.filter((item) => item !== keywordName));
  };

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <TextField
          id="standard-full-width"
          label="키워드"
          style={{ margin: 8 }}
          placeholder="핸드폰"
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setKeyword(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              addKeyword();
            }
          }}
        />
        <Button variant="contained" color="primary" onClick={addKeyword}>
          추가
        </Button>
        <div style={{ justifyContent: 'flex-start' }}>
          {keywordArray.map((item, index) => {
            return (
              <Chip
                style={{ marginLeft: 8 }}
                size="small"
                label={item}
                // onDelete={handleDelete}
                onDelete={() => {
                  removeKeyword(item);
                }}
              />
            );
          })}
        </div>
      </Paper>
    </Grid>
  );
};
