import React, { useEffect, useState } from 'react';
import { MenuItem, Select } from '@mui/material';
import { useCookies } from 'react-cookie';
import { useRecoilState } from 'recoil';
import { atomGroupNo, customGroupList } from '../../../store/CustomGroupAtom';
import { rankingDataList } from '../../../store/RankingAtom';
import { errorMessage, GLOBAL_HOST_URI } from '../../../common/CommonFunc';
import axios from 'axios';

const listCronData = async (
  customGroupNo: number,
  custNo: string,
  custAuth?: string | undefined,
) => {
  const cronUrl = custAuth === 'V' ? 'cron/listViewer' : 'cron/list';

  try {
    const { data } = await axios.post(
      GLOBAL_HOST_URI + 'cron/list',
      {
        shoppingGroupNo: customGroupNo,
      },
      {
        headers: { Authorization: custNo },
      },
    );

    return data;
  } catch (e) {
    errorMessage(e);
  }
};

const FilterGroupSelectBox = () => {
  const [groupNo, setGroupNo] = useRecoilState(atomGroupNo);
  const [rankingData, setRankingData] = useRecoilState(rankingDataList);
  const [cookies, setCookie, removeCookie] = useCookies(['custNo', 'custAuth']);
  const [groupList, setGroupList] = useRecoilState(customGroupList);

  const handleChange = async event => {
    setGroupNo(event.target.value);

    // refreshList(event.target.value, cookies.custNo);
    let data = await listCronData(event.target.value, cookies.custNo);
    setRankingData(data);
  };

  if (groupList === undefined) return null;

  return (
    <Select
      value={groupNo}
      onChange={handleChange}
      displayEmpty
      inputProps={{ 'aria-label': 'Without label' }}
    >
      <MenuItem value={'0'}>
        <em>전체</em>
      </MenuItem>
      <MenuItem value={'-1'}>
        <em>없음</em>
      </MenuItem>
      {groupList.map((item, index) => (
        <MenuItem key={index} value={item.shoppingGroupNo}>
          {item.shoppingGroupName}
        </MenuItem>
      ))}
    </Select>
  );
};

export default FilterGroupSelectBox;
