import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  makeStyles,
  Container,
} from '@material-ui/core';
import axios from 'axios';
import { GLOBAL_HOST_URI, errorMessage } from '../../../../common/CommonFunc';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  buttonGroup: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

function NoticeManagement() {
  const classes = useStyles();
  const [notices, setNotices] = useState([]);
  const [cookies] = useCookies(['custNo']);
  const history = useHistory(); // history 객체 가져오기

  useEffect(() => {
    getNotice();
  }, []);

  async function getNotice() {
    try {
      const { data } = await axios.get(GLOBAL_HOST_URI + 'notices/', {
        headers: { Authorization: cookies.custNo },
      });

      setNotices(data.data);
    } catch (e) {
      errorMessage(e);
    }
  }

  const handleEdit = noticeNo => {
    history.push('/my/notice/register/' + noticeNo); // 페이지 이동
  };

  const handleDelete = async noticeNo => {
    try {
      if (!confirm('정말 삭제하시겠습니까?')) {
        return false;
      }

      const { data } = await axios.delete(
        GLOBAL_HOST_URI + 'notices/' + noticeNo,
        {
          headers: { Authorization: cookies.custNo },
        },
      );

      if (data.message === 'NOTICE_DELETE') {
        alert('공지사항을 삭제하였습니다.');
        getNotice();
      } else {
        alert('공지사항을 삭제하는데 실패하였습니다.');
      }
    } catch (e) {
      errorMessage(e);
    }
  };

  const handleWrite = () => {
    // 공지사항 쓰기 페이지로 이동하는 로직을 추가하세요
    history.push('/my/notice/register'); // 페이지 이동
  };

  return (
    <Container>
      <TableContainer component={Paper}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleWrite}
          style={{ marginTop: 16, marginBottom: 16, marginLeft: 16 }}
        >
          공지사항 쓰기
        </Button>
        <Table className={classes.table} aria-label="notice table">
          <TableHead>
            <TableRow>
              <TableCell>번호</TableCell>
              <TableCell>제목</TableCell>
              <TableCell>옵션</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notices.map(notice => (
              <TableRow key={notice.noticeNo}>
                <TableCell>{notice.noticeNo}</TableCell>
                <TableCell>{notice.title}</TableCell>
                <TableCell className={classes.buttonGroup}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleEdit(notice.noticeNo)}
                  >
                    수정
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleDelete(notice.noticeNo)}
                  >
                    삭제
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default NoticeManagement;
