import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import { GLOBAL_HOST_URI, errorMessage } from '../../../../common/CommonFunc';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

const PaymentHistoryPopup = ({ open, onClose, customNo }) => {
  const [cookies] = useCookies(['custNo']);
  const [refundAmount, setRefundAmount] = useState('');
  const [paymentData, setPaymentData] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState(null);

  async function init() {
    setSelectedPayment(null);
    try {
      const { data } = await axios.get(
        GLOBAL_HOST_URI + 'admin/payment?customNo=' + customNo,
        {
          headers: { Authorization: cookies.custNo },
        },
      );

      setPaymentData(data.data);
    } catch (e) {
      errorMessage(e);
    }
  }

  useEffect(() => {
    if (open === true) {
      init();
    }
  }, [open]);

  async function refund(payment) {
    if (payment === null) {
      alert('환불건을 선택해주세요.');
      return false;
    }

    if (parseInt(refundAmount) <= 0) {
      alert('환불금액은 0원보다 커야합니다.');
      return false;
    }

    if (payment.amount < parseInt(refundAmount)) {
      alert('결제금액보다 환불금액이 클 수 없습니다.');
      return false;
    }

    try {
      const { data } = await axios.put(
        `${GLOBAL_HOST_URI}admin/payment/refund?customNo=${customNo}&tid=${payment.paymentKey}&cancelAmount=${refundAmount}`,
        {}, // 빈 객체 전달
        {
          headers: { Authorization: cookies.custNo },
        },
      );

      console.log('data', data);

      if (
        data.status === 'PART_CANCEL_PAYMENT' ||
        data.status === 'CANCEL_PAYMENT'
      ) {
        alert('환불하였습니다.');
        init();
      } else {
        alert('환불에 실패하였습니다.' + data.status);
      }

      // setPaymentData(data.data);
    } catch (e) {
      errorMessage(e);
    }
  }

  const handlePaymentSelect = index => {
    setSelectedPayment(paymentData[index]);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>결제내역</DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>결제금액</TableCell>
                <TableCell>결제일</TableCell>
                <TableCell>환불여부</TableCell>
                <TableCell>환불 사유</TableCell>
                <TableCell>환불 금액</TableCell>
                <TableCell>구매자이름</TableCell>
                {/* <TableCell>주문번호</TableCell> */}
                <TableCell>결제 PG</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentData.length >= 1 &&
                paymentData.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <input
                          type="radio"
                          value={index}
                          checked={selectedPayment === item}
                          onChange={() => handlePaymentSelect(index)}
                          disabled={item.cancelYN === 'Y'}
                        />
                      </TableCell>
                      <TableCell>
                        {item.amount
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </TableCell>
                      <TableCell>{item.createdAt}</TableCell>
                      <TableCell>{item.cancelYN}</TableCell>
                      <TableCell>{item.cancelReason}</TableCell>
                      <TableCell>
                        <div>
                          {item.cancelAmount
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </div>
                        {/* {item.cancelAmount > 0 && (
                          <Button
                            variant="contained"
                            onClick={() => refund(selectedPayment)}
                          >
                            자세히보기
                          </Button>
                        )} */}
                      </TableCell>
                      <TableCell>{item.customerName}</TableCell>
                      {/* <TableCell>{item.orderId}</TableCell> */}
                      <TableCell>{item.paymentType}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <TextField
            label="환불 금액"
            variant="outlined"
            fullWidth
            value={refundAmount}
            onChange={e => setRefundAmount(e.target.value)}
            type="number"
          />
          <Button variant="contained" onClick={() => refund(selectedPayment)}>
            환불
          </Button>
        </div>
        <div>
          <Button onClick={onClose}>닫기</Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentHistoryPopup;
