import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {
  CircularProgress,
  Button,
  ButtonGroup,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import { errorMessage, GLOBAL_HOST_URI } from '../common/CommonFunc';
import CreateIcon from '@material-ui/icons/Create';
import { Alert } from '@material-ui/lab/Alert';
import { useCookies } from 'react-cookie';
import { customGroupList } from '../store/CustomGroupAtom';
import { useRecoilState } from 'recoil';
import { initGroupList } from '../screens/rankingCronKeyword/utils/utils';

function getModalStyle() {
  return {
    position: 'fixed',
    margin: '0 auto',
    left: 0,
    right: 0,
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function AccountGroupComponent() {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [groupName, setGroupName] = useState('');
  const [cookies, setCookie, removeCookie] = useCookies(['custNo']);
  const [groupList, setGroupList] = useRecoilState(customGroupList);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addKeyword = async () => {
    if (groupName.trim() === '') {
      alert('사용자 그룹 이름을 입력해주세요.');
      return false;
    }

    try {
      const { data } = await axios.post(
        GLOBAL_HOST_URI + 'shoppinggroup',
        {
          shoppingGroupName: groupName,
        },
        {
          headers: { Authorization: cookies.custNo },
        },
      );

      if (data === 0) {
        const groupList = await initGroupList(cookies.custNo);
        setGroupList(groupList);

        alert('등록에 성공하였습니다.');
        handleClose();
      } else {
        alert('등록에 실패하였습니다.\n새로고침 후 다시 시도해주세요.');
      }
    } catch (e) {
      errorMessage(e);
    }
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">사용자 그룹 추가하기</h2>
      <TextField
        id="standard-keyword"
        label="사용자 그룹 이름"
        onChange={e => {
          setGroupName(e.target.value);
        }}
        style={{ margin: 8 }}
        placeholder="ex) 메이튼"
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Button variant="outlined" onClick={handleClose}>
        닫기
      </Button>
      <Button variant="outlined" onClick={addKeyword}>
        생성하기
      </Button>
    </div>
  );

  return (
    <div>
      <Button href="#" color="primary" onClick={handleOpen}>
        <CreateIcon />
        사용자 그룹 추가
      </Button>
      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}

const StoreUrlComponent = props => {
  const { setProductCode, setProductName, smartStoreUrl, setSmartStoreUrl } =
    props;
  const [loading, setLoading] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['custNo', 'custAuth']);

  const getStoreData = async () => {
    if (smartStoreUrl.indexOf('naver.com') === -1) {
      alert('스토어url 형식으로 입력해주세요.');
      return false;
    }

    try {
      setLoading(true);

      const { data } = await axios.post(
        GLOBAL_HOST_URI + 'search/getProductAnalyze',
        {
          url: smartStoreUrl,
        },
        {
          headers: { Authorization: cookies.custNo },
        },
      );
      if (data.mainCode === '') {
        alert('상품정보가 존재하지 않습니다.\n스토어url을 다시 확인해주세요.');
      } else {
        setProductCode(data.mainCode);
        setProductName(data.productTitle);
      }
    } catch (e) {
      errorMessage(e);
    }

    setLoading(false);
  };

  return (
    <>
      <TextField
        id="standard-full-width"
        label="스토어url"
        style={{ margin: 8 }}
        placeholder="https://smartstore.naver.com/storeid/products/1234567890"
        fullWidth
        required
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        value={smartStoreUrl}
        onChange={e => {
          setSmartStoreUrl(e.target.value);
        }}
      />
      {loading === true ? (
        <CircularProgress />
      ) : (
        <Button variant="contained" color="primary" onClick={getStoreData}>
          스토어 불러오기
        </Button>
      )}
    </>
  );
};
