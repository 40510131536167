import { Button, Grid, Paper } from '@material-ui/core';
import { Stack } from '@mui/material';
import { useHistory } from 'react-router';

export default function ManagementPayment() {
  const history = useHistory(); // history 객체 가져오기

  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
      <Stack direction="row" spacing={2}>
        <Button
          variant="contained"
          onClick={() => {
            history.push('/my/accountmanagement');
          }}
        >
          사용자관리
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            history.push('/my/notice');
          }}
        >
          공지사항
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            history.push('/my/payment-management');
          }}
        >
          결제관리
        </Button>
      </Stack>
    </div>
  );
}
