import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {
  Button,
  Checkbox,
  TextareaAutosize,
  Typography,
} from '@material-ui/core';
import { GLOBAL_HOST_URI } from '../../common/CommonFunc';
import axios from 'axios';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: 12,
    marginTop: 16,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const DefaultInfo = props => {
  const classes = useStyles();

  const { password, setPassword, passwordCheck, setPasswordCheck, id, setId } =
    props;

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Typography style={{ textAlign: 'left' }}>기본정보</Typography>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="Id"
          label="아이디"
          name="Id"
          autoComplete="Id"
          autoFocus
          onChange={e => {
            setId(e.target.value);
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <br />
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="Password"
              label="비밀번호"
              name="Password"
              type="password"
              autoComplete="current-password"
              onChange={e => {
                setPassword(e.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="Password"
              label="비밀번호 확인"
              type="password"
              autoComplete="Password"
              onChange={e => {
                setPasswordCheck(e.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

const IndividualInfo = props => {
  const { setName, setNickname } = props;
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Typography style={{ textAlign: 'left' }}>개인정보</Typography>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="name"
          label="이름"
          name="name"
          autoComplete="name"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={e => {
            setName(e.target.value);
          }}
        />
        <br />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="nickname"
          label="닉네임"
          name="nickname"
          autoComplete="nickname"
          onChange={e => {
            setNickname(e.target.value);
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Paper>
    </Grid>
  );
};

const PolicyComponent = () => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Typography style={{ textAlign: 'left' }}>회원가입약관</Typography>
        <textarea name="" rows="" cols="" disabled style={{ width: '100%' }}>
          aria-label="maximum height" placeholder="Maximum 4 rows"
          defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit,
          sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        </textarea>
        <br />
        <Typography style={{ textAlign: 'left' }}>회원가입정책</Typography>
        <TextareaAutosize
          style={{ alignContent: 'flex-start' }}
          rowsMax={4}
          aria-label="maximum height"
          placeholder="Maximum 4 rows"
          defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua."
        />
        <br />
        <Checkbox
          checked={true}
          // onChange={handleChange}
          name="checkedB"
          color="primary"
        />
        회원가입 약관에 모두 동의합니다.
      </Paper>
    </Grid>
  );
};

const AccountJoin = props => {
  const { id, passwordCheck, name, nickname, history } = props;
  const classes = useStyles();

  const insertAccount = async () => {
    if (!/^[a-zA-Z0-9]{4,20}$/.test(id)) {
      alert('아이디는 4~20글자(영어,숫자)만 허용됩니다.');
      return false;
    }

    var checkNumber = passwordCheck.search(/[0-9]/g);
    var checkEnglish = passwordCheck.search(/[a-z]/gi);
    if (checkNumber < 0 || checkEnglish < 0) {
      alert('비밀번호는 숫자와 영문자를 혼용하여야 합니다.');
      return false;
    }

    if (!/^[가-힣]{2,5}$/.test(name)) {
      alert('이름은 2~5글자만 허용됩니다.');
      return false;
    }

    if (!/^[a-zA-Z0-9가-힣]{2,8}$/.test(nickname)) {
      alert('닉네임은 2~8글자만 허용됩니다.');
      return false;
    }

    const { data } = await axios.post(GLOBAL_HOST_URI + 'custom/insert', {
      customName: name,
      customId: id,
      customPassword: passwordCheck,
      customNickname: nickname,
    });

    if (data === 0) {
      alert('회원가입이 되었습니다.');
      history.push('/account/login');
    } else if (data === -98) {
      alert('이미 존재하는 아이디입니다.');
    } else {
      alert('회원가입에 실패하였습니다.\n다시시도해주세요.');
    }
  };

  return (
    <Button
      fullWidth
      variant="contained"
      color="primary"
      className={classes.submit}
      onClick={insertAccount}
    >
      회원가입
    </Button>
  );
};

export default function SignUpScreen({ location, history }) {
  const classes = useStyles();

  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  const [passwordCheck, setPasswordCheck] = useState('');
  const [name, setName] = useState('');
  const [nickname, setNickname] = useState('');

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <DefaultInfo
          setId={setId}
          setPassword={setPassword}
          setPasswordCheck={setPasswordCheck}
        />
        <IndividualInfo setName={setName} setNickname={setNickname} />
        {/* <PolicyComponent setAgree={setAgree} agree={setAgree} /> */}
        <AccountJoin
          id={id}
          passwordCheck={passwordCheck}
          name={name}
          nickname={nickname}
          history={history}
        />
      </Grid>
    </div>
  );
}
