import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { CssBaseline } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { GLOBAL_HOST_URI } from './CommonFunc';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

export default function ButtonAppBar({ category }) {
  const classes = useStyles();
  const [cookies, setCookie, removeCookie] = useCookies(['custNo', 'custAuth']);
  // const [signComponent, setSignComponent] = useState('login');

  const logout = async () => {
    try {
      await axios.post(GLOBAL_HOST_URI + 'custom/logout', null, {
        headers: { Authorization: cookies.custNo },
      });
    } catch (e) {
    } finally {
      removeCookie('custNo');
      removeCookie('custAuth');

      window.location.replace('/');
    }
  };

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" className={category ? classes.appBar : null}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            // onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap className={classes.title}>
            {/* Responsive drawer */}
          </Typography>
          {cookies.custNo !== undefined ? (
            <Button color="inherit" onClick={logout}>
              Logout
            </Button>
          ) : (
            <Button color="inherit" component={Link} to="/account/login">
              Login
            </Button>
          )}
          {cookies.custAuth === 'A' ? (
            <Button color="inherit" component={Link} to="/my/management">
              관리자설정
            </Button>
          ) : null}
          {/* <Button color="inherit" component={Link} to="/my/myaccount">
            내정보
          </Button> */}
        </Toolbar>
      </AppBar>
    </>
  );
}
