import {
  Button,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import axios from 'axios';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { errorMessage, GLOBAL_HOST_URI } from '../common/CommonFunc';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const style = {
  display: 'flex',
  justifyContent: 'center',
};

export default function ProductAnalyzeScreen({ location, history }) {
  const classes = useStyles();
  const [url, setUrl] = useState('');
  const [storeData, setStoreData] = useState(null);
  const [cookies] = useCookies(['custNo', 'custAuth']);

  const search = async () => {
    try {
      const { data } = await axios.post(
        GLOBAL_HOST_URI + 'search/getProductAnalyze',
        {
          url: url,
        },
        {
          headers: { Authorization: cookies.custNo },
        },
      );

      setStoreData(data);
    } catch (e) {
      errorMessage(e);
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <TextField
              id="filled-basic"
              label="스마트스토어 주소"
              variant="filled"
              placeholder="https://smartstore.naver.com/hero/products/1234567890"
              fullWidth
              autoFocus
              onChange={e => {
                setUrl(e.target.value);
              }}
              onKeyDown={e => search(e.keyCode)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Button title="검색" />
          </Paper>
        </Grid>
        {storeData !== null ? (
          <>
            <Grid item xs={4}>
              <Paper className={classes.paper}>
                <img src={storeData.image} width="320" height="320" />
              </Paper>
            </Grid>
            <Grid item xs={8}>
              <Grid item container>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    <Typography style={{ textAlign: 'center', fontSize: 24 }}>
                      {storeData.productTitle}
                    </Typography>
                    <Typography>{storeData.description}</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    <Typography style={{ textAlign: 'center', fontSize: 24 }}>
                      키워드
                    </Typography>
                    <Typography>{storeData.keywords.toString()}</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    <Typography style={{ textAlign: 'center', fontSize: 24 }}>
                      메인코드
                    </Typography>
                    <Typography>{storeData.mainCode}</Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : null}

        <CardComponent
          title="가격"
          contents="1,900원"
          icon={<AttachMoneyIcon contentStyleType={{ flex: 1 }} />}
          size={4}
        />
        <CardComponent
          title="리뷰수"
          contents="1,245"
          icon={<AttachMoneyIcon contentStyleType={{ flex: 1 }} />}
          size={4}
        />
        <CardComponent
          title="평점"
          contents="4.5"
          icon={<AttachMoneyIcon contentStyleType={{ flex: 1 }} />}
          size={4}
        />
      </Grid>
    </div>
  );
}

const CardComponent = props => {
  const { title, contents, icon, size } = props;
  const classes = useStyles();

  return (
    <Grid item xs={size}>
      <Paper className={classes.paper}>
        <div style={{ flexDirection: 'row' }}>
          {icon}
          <div style={{ flex: 1 }}>
            <Typography
              style={{
                textAlign: 'right',
                fontSize: 24,
                color: 'gray',
                flex: 1,
              }}
            >
              {title}
            </Typography>
            <Typography
              style={{
                textAlign: 'right',
                fontSize: 24,
                color: 'black',
                flex: 1,
              }}
            >
              {contents}
            </Typography>
          </div>
        </div>
      </Paper>
    </Grid>
  );
};
