import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  root: {
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    marginTop: 22,
  },
});

const style = {
  display: 'flex',
  justifyContent: 'center',
};

export default function RankingDetailScreen() {
  const classes = useStyles();
  const [rankingData, setRankingData] = useState([]);

  return (
    <div>
      <Typography variant="h4" style={style}>
        랭킹 상세 분석
      </Typography>
    </div>
  );
}
