import axios from 'axios';
import { errorMessage, GLOBAL_HOST_URI } from '../../../common/CommonFunc';

const initGroupList = async (custNo: string) => {
  try {
    const { data } = await axios.post(
      GLOBAL_HOST_URI + 'custom/listCustomGroup',
      null,
      {
        headers: { Authorization: custNo },
      },
    );

    if (data !== -89) {
      return data;
    }
  } catch (e) {
    errorMessage(e);
  }
};

export { initGroupList };
