import React, { useEffect, useState } from 'react';
import { MenuItem, Select } from '@mui/material';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useRecoilState } from 'recoil';
import { errorMessage, GLOBAL_HOST_URI } from '../../../common/CommonFunc';
import { atomGroupNo, customGroupList } from '../../../store/CustomGroupAtom';
import { Typography } from '@material-ui/core';

const DataGridSelectBox = props => {
  const { keywordCronNo, groupNo } = props;
  const [selectedGroupNo, setSelectedGroupNo] = useState<number>(0);
  const [groupList, setGroupList] = useRecoilState(customGroupList);
  const [cookies, setCookie, removeCookie] = useCookies(['custNo', 'custAuth']);

  useEffect(() => {
    groupNo === undefined ? setSelectedGroupNo(0) : setSelectedGroupNo(groupNo);
  }, [groupList]);

  const handleChange = async event => {
    if (confirm('그룹을 변경하시겠습니까?')) {
      try {
        let shoppingGroupNo = event.target.value;
        if (event.target.value === '0') {
          shoppingGroupNo = null;
        }

        const { data } = await axios.post(
          GLOBAL_HOST_URI + 'cron/updateGroupCustom',
          {
            keywordCronNo: keywordCronNo,
            shoppingGroupNo: shoppingGroupNo,
          },
          {
            headers: { Authorization: cookies.custNo },
          },
        );
        setSelectedGroupNo(event.target.value);
      } catch (e) {
        errorMessage(e);
      }
    }
  };

  return (
    <div>
      <Typography>그룹명</Typography>

      <Select
        value={selectedGroupNo}
        onChange={handleChange}
        displayEmpty
        // className={classes.selectEmpty}
        inputProps={{ 'aria-label': 'Without label' }}
      >
        <MenuItem value={'0'}>
          <em>없음</em>
        </MenuItem>
        {groupList.map((item, index) => {
          return (
            <MenuItem key={index} value={item.shoppingGroupNo}>
              {item.shoppingGroupName}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
};

export default DataGridSelectBox;
