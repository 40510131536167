import { FirebaseApp, initializeApp } from 'firebase/app';
import { Database, getDatabase } from 'firebase/database';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCPGhEYKdq0lDDJxnNbSA2Lw991I0sJzmI',
  authDomain: 'heroranking-cc963.firebaseapp.com',
  databaseURL: 'https://heroranking-cc963-default-rtdb.firebaseio.com',
  projectId: 'heroranking-cc963',
  storageBucket: 'heroranking-cc963.appspot.com',
  messagingSenderId: '831178170460',
  appId: '1:831178170460:web:ec04ee01ccf87299b58699',
  measurementId: 'G-L84ZB27FCY',
};
const app: FirebaseApp = initializeApp(firebaseConfig);

// Get a reference to the database service
const database: Database = getDatabase(app);

export default database;
