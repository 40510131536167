import { CircularProgress, Divider } from '@material-ui/core';
import Box from '@material-ui/core/Box';
// import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
// import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import axios, { AxiosResponse } from 'axios';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { GLOBAL_HOST_URI } from '../../common/CommonFunc';
import LoginInfo from './types/LoginInfo';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href={'/'}>
        Hero Ranking
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  social: {
    margin: theme.spacing(1, 0, 1),
    padding: theme.spacing(1, 0, 1),
  },
}));

export default function SignInScreen() {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <form className={classes.form} noValidate>
          <LoginComponent />
          <Grid container>
            {/* <Grid item xs>
              <Link href="#" variant="body2">
                비밀번호 찾기
              </Link>
            </Grid> */}
            {/* <Grid item>
              <Link href="/account/signup" variant="body2">
                {'회원가입'}
              </Link>
            </Grid> */}
          </Grid>
        </form>
      </div>
      <Divider />
      {/* <NaverLogin />
      <KakaoLogin />
      <FacebookLogin /> */}

      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

const LoginComponent = () => {
  const classes = useStyles();
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  const [cookies, setCookie, removeCookie] = useCookies(['custNo', 'custAuth']);
  const [loading, setLoading] = useState(false);

  const login = async () => {
    setLoading(true);
    const { data }: AxiosResponse<LoginInfo> = await axios.post(
      GLOBAL_HOST_URI + 'custom/login',
      {
        customId: id,
        customPassword: password,
      },
    );

    const resData = data.data;

    if (resData.token === '') {
      alert('아이디나 비밀번호가 틀렸습니다.');
      setLoading(false);
    } else {
      setCookie('custNo', resData.token, { path: '/' });
      setCookie('custAuth', resData.customAuth, { path: '/' });
      window.location.replace('/');
    }
  };

  return (
    <>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="Id"
        label="Id"
        name="Id"
        autoComplete="Id"
        autoFocus
        value={id}
        onKeyDown={e => {
          if (e.keyCode === 13) {
            login();
          }
        }}
        onChange={e => {
          setId(e.target.value);
        }}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        value={password}
        onChange={e => {
          setPassword(e.target.value);
        }}
        onKeyDown={e => {
          if (e.keyCode === 13) {
            login();
          }
        }}
      />
      {/* <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="아이디 저장" /> */}
      {loading === true ? (
        <div style={{ alignItems: 'center' }}>
          <CircularProgress />
        </div>
      ) : (
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={login}
        >
          Login
        </Button>
      )}
    </>
  );
};

// const NaverLogin = () => {
//   const classes = useStyles();
//   return (
//     <Button
//       fullWidth
//       variant="contained"
//       color="primary"
//       className={classes.social}
//       onClick={() => {
//         // history.push('/');
//       }}>
//       네이버 로그인
//     </Button>
//   );
// };

// const KakaoLogin = () => {
//   const classes = useStyles();
//   return (
//     <Button
//       fullWidth
//       variant="contained"
//       color="primary"
//       className={classes.social}
//       onClick={() => {
//         // history.push('/');
//       }}>
//       카카오 로그인
//     </Button>
//   );
// };

// const FacebookLogin = () => {
//   const classes = useStyles();
//   return (
//     <Button
//       fullWidth
//       variant="contained"
//       color="primary"
//       className={classes.social}
//       onClick={() => {
//         // history.push('/');
//       }}>
//       페이스북 로그인
//     </Button>
//   );
// };
