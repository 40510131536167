import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Typography,
  Container,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { GLOBAL_HOST_URI, errorMessage } from '../../../../common/CommonFunc';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
}));

function Noticeregister() {
  const classes = useStyles();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [cookies] = useCookies(['custNo']);
  const history = useHistory(); // history 객체 가져오기

  const { noticeId } = useParams();

  const handleTitleChange = event => {
    setTitle(event.target.value);
  };

  const handleContentChange = event => {
    setContent(event.target.value);
  };

  async function getNotice() {
    try {
      const { data } = await axios.get(
        GLOBAL_HOST_URI + 'notices/' + noticeId,
        {
          headers: { Authorization: cookies.custNo },
        },
      );
      setTitle(data.data.title);
      setContent(data.data.contents);
    } catch (e) {
      errorMessage(e);
    }
  }

  const updateNotice = async () => {
    try {
      const { data } = await axios.put(
        GLOBAL_HOST_URI + 'notices/' + noticeId,
        {
          title: title,
          contents: content,
        },
        {
          headers: { Authorization: cookies.custNo },
        },
      );
      console.log('data', data);
      if (data.message === 'NOTICE_UPDATE') {
        alert('공지사항을 수정하였습니다.');
      } else {
        alert('공지사항을 수정하는데 실패하였습니다.');
      }
    } catch (e) {
      errorMessage(e);
    }
  };

  if (noticeId !== undefined) {
    useEffect(() => {
      getNotice();
    }, []);

    return (
      <Container maxWidth="sm" className={classes.container}>
        <Typography variant="h5" gutterBottom className={classes.title}>
          공지사항 수정
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="제목"
              variant="outlined"
              value={title}
              onChange={handleTitleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="내용"
              multiline
              rows={4}
              variant="outlined"
              value={content}
              onChange={handleContentChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={updateNotice}>
              수정하기
            </Button>
          </Grid>
        </Grid>
      </Container>
    );
  }

  const handleSubmit = async () => {
    try {
      const { data } = await axios.post(
        GLOBAL_HOST_URI + 'notices/',
        {
          title: title,
          contents: content,
        },
        {
          headers: { Authorization: cookies.custNo },
        },
      );

      if (data.message === 'NOTICE_INSERT') {
        alert('공지사항을 작성하였습니다.');
        history.push('/my/notice');
      }
    } catch (e) {
      errorMessage(e);
    }

    console.log('Submitted Notice:', { title, content });
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      <Typography variant="h5" gutterBottom className={classes.title}>
        공지사항 작성
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="제목"
            variant="outlined"
            value={title}
            onChange={handleTitleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="내용"
            multiline
            rows={4}
            variant="outlined"
            value={content}
            onChange={handleContentChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            작성하기
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Noticeregister;
